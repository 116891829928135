/* Remove bottom border for MDBDataTable headers */
.mdb-table thead th {
  border-bottom: none !important; /* Removes the bottom border from column headers */
}

/* Remove bottom border for DataTables headers */
table.dataTable thead th.sorting_asc,
table.dataTable thead th.sorting_desc,
table.dataTable thead th.sorting,
table.dataTable thead td.sorting_asc,
table.dataTable thead td.sorting_desc,
table.dataTable thead td.sorting {
  border-bottom: none !important; /* Ensures no bottom border for sorting headers */
}

/* Remove bottom border for DataTables headers when no footer is present */
table.dataTable.no-footer thead th {
  border-bottom: none !important; /* For DataTables without footer */
}

/* Remove bottom border for DataTables scroll head */
div.dataTables_scrollHead table.dataTable {
  border-bottom: none !important; /* Remove border at scroll head */
}

/* Remove top border and adjust margin for DataTables scroll body */
div.dataTables_scrollBody table {
  border-top: none !important; /* Remove top border from scroll body */
  margin-top: 0 !important; /* Remove top margin */
  margin-bottom: 0 !important; /* Remove bottom margin */
}

/* Remove bottom border for DataTables scroll body headers */
div.dataTables_scrollBody table thead th {
  border-bottom: none !important; /* Remove border from thead in scroll body */
}

/* Remove top border for DataTables scroll foot */
div.dataTables_scrollFoot > .dataTables_scrollFootInner > table {
  border-top: none !important; /* Remove border from scroll foot */
}

/* Ensure no bottom border on small screens */
@media screen and (max-width: 767px) {
  .mdb-table thead th {
    border-bottom: none !important; /* Ensure no border on small screens */
  }
}

/* Adjustments for small tables */
table.dataTable.table-sm > thead > tr > th {
  padding-right: 20px;
}

table.dataTable.table-sm .sorting:before,
table.dataTable.table-sm .sorting_asc:before,
table.dataTable.table-sm .sorting_desc:before {
  top: 5px;
  right: 0.85em;
}

table.dataTable.table-sm .sorting:after,
table.dataTable.table-sm .sorting_asc:after,
table.dataTable.table-sm .sorting_desc:after {
  top: 5px;
}

/* Remove borders for table-bordered DataTables */
table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
  border-left-width: 0;
}

table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable td:last-child {
  border-right-width: 0;
}

table.table-bordered.dataTable tbody th,
table.table-bordered.dataTable tbody td {
  border-bottom-width: 0;
}

div.dataTables_scrollHead table.table-bordered {
  border-bottom-width: 0;
}

/* Adjust margins for responsive tables */
div.table-responsive > div.dataTables_wrapper > div.row {
  margin: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:first-child {
  padding-left: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:last-child {
  padding-right: 0;
}

/* Styles for action icons */
.action-icons {
  display: flex;
  align-items: center;
}

.action-icons svg {
  margin: 0 5px;
}

/* Styles for striped tables */
.table-striped tbody tr:nth-child(odd) {
  background-color: #f8f9fa; /* Light gray */
}

.table-striped tbody tr:nth-child(even) {
  background-color: #ffffff; /* White */
}

/* Styles for pagination items */
.page-item:not(:first-child) .page-link {
  margin-left: 3px;
}
// for hiding the footer in the  table 
tbody + thead {
  display: none;
}
