/* Set the background color of the table to white */
.media-table {
    background-color: white;
  }
  
  /* Add a dotted border around the image input when selected */
  .file-input {
    border: 1px dashed transparent;
  }
  
  .file-input:focus {
    border-color: #000; /* You can customize the color as needed */
    outline: none;
  }
  .dropzone-previews {
    display: flex;
    flex-wrap: wrap;
    
    justify-content: flex-start
  }
  
  .dropzone-previews .card {
    border: 2px dashed #ced4da; /* Adjust the color and style as needed */
    margin-right: 1rem; /* Space between images */
    margin-bottom: 1rem; /* Space below images */
    border-radius: 0.25rem; /* Rounded corners */
  }
  
  .dropzone-previews img {
    border: 1px dashed #ced4da; /* Adjust the color and style as needed */
  }
  .dropzone-previews .card {
    cursor: move; /* Default cursor when hovering over draggable items */
  }
  
  .dropzone-previews .card.dragging {
    opacity: 0.5; /* Optionally make the item transparent while dragging */
  }
  